// @todo Razbij me na manje komponente prije nego neko vidi... pretty please
import {
    AcademicCapIcon,
    BriefcaseIcon,
    MenuIcon,
    LocationMarkerIcon,
    ViewGridIcon,
    XIcon,
    OfficeBuildingIcon,
    PencilAltIcon,
} from "@heroicons/react/outline";
import Router from "next/router";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { cities, department, levels, types } from "../../../common/constants";
import { signOut, useSession } from "next-auth/react";
import { useEffect, useState } from "react";

const solutions = [
    {
        name: "Lokacija",
        value: cities.map((city) => ({
            label: city.name,
            href: `/lokacija/${city.slug}/1`,
        })),
        icon: LocationMarkerIcon,
    },
    {
        name: "Pozicija",
        value: department.map((dep) => ({
            label: dep.name,
            href: `/department/${dep.slug}/1`,
        })),
        icon: BriefcaseIcon,
    },
    {
        name: "Iskustvo",
        value: levels.map((lvl) => ({
            label: lvl.name,
            href: `/iskustvo/${lvl.slug}/1`,
        })),
        icon: AcademicCapIcon,
    },
    {
        name: "Tip posla",
        value: types.map((type) => ({
            label: type.name,
            href: `/angazman/${type.slug}/1`,
        })),
        icon: ViewGridIcon,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Header() {
    const [open, setOpen] = useState(false);

    const { data: session, status } = useSession();

    const isAdmin = session?.user?.role === "ADMIN";
    const isCompanyPremium = session?.company?.plan === "PREMIUM";

    useEffect(() => {
        Router.events.on("routeChangeComplete", () => setOpen(false));
    }, []);

    return (
        <div className="relative bg-white px-4 sm:px-6">
            <div className="max-w-screen-xl mx-auto">
                <div className="flex justify-between items-center py-6 md:space-x-10">
                    <div className="flex min-w-[60px]">
                        <Link href="/">
                            <a>
                                <img
                                    className="h-8 w-auto sm:h-10"
                                    src="/logo.svg"
                                    alt=""
                                />
                            </a>
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <div className="absolute right-20 top-6 ">
                            <Link href="/objavi-oglas">
                                <button className="btn-primary self-center py-2 px-5 mr-2 text-sm">
                                    Objavi oglas
                                </button>
                            </Link>
                            {status === "unauthenticated" && (
                                <Link
                                    href={`/auth/signin?redirect=${window.location.href}`}
                                >
                                    <button className="btn-accent self-center py-2 px-5 text-sm">
                                        Prijava
                                    </button>
                                </Link>
                            )}
                        </div>

                        {/* {status === "authenticated" && (
              <Link href="/ostavi-utisak">
                <button className="btn-accent self-center py-2 px-5 absolute right-20 top-6 text-sm">
                  Ostavi iskustvo
                </button>
              </Link>
            )} */}
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <button
                            onClick={() => setOpen(true)}
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center"
                        >
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden md:flex space-x-10">
                        <Link href="/">
                            <a className="text-base font-medium hover:text-blue py-1">
                                Početna
                            </a>
                        </Link>
                        <div className="relative group">
                            <Link href="/poslovi/1">
                                <a>
                                    <button className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-blue py-1">
                                        <span>Poslovi</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                !open && "text-blue",
                                                "ml-2 h-5 w-5 group-hover:text-gray-dark"
                                            )}
                                            aria-hidden="true"
                                        />
                                    </button>
                                </a>
                            </Link>
                            <div className="hidden group-hover:block absolute z-10 -ml-4 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                        {solutions.map((item) => (
                                            <div
                                                key={item.name}
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-light"
                                            >
                                                <item.icon
                                                    className="flex-shrink-0 h-6 w-6 text-blue"
                                                    aria-hidden="true"
                                                />
                                                <div className="ml-4">
                                                    <p className="text-base font-medium">
                                                        {item.name}
                                                    </p>
                                                    <p className="mt-1 leading-8 text-sm city-list">
                                                        {item.value.map((v) => (
                                                            <span
                                                                key={v.href}
                                                                className="text-base text-gray-dark hover:text-blue leading-8 mr-2"
                                                            >
                                                                <Link
                                                                    href={
                                                                        v.href
                                                                    }
                                                                >
                                                                    <a>
                                                                        {
                                                                            v.label
                                                                        }
                                                                        <span>{`, `}</span>
                                                                    </a>
                                                                </Link>
                                                            </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* KOMPANIJE */}
                        <div className="relative group">
                            <Link href="/kompanije">
                                <a>
                                    <button className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-blue py-1">
                                        <span>Kompanije</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                !open && "text-blue",
                                                "ml-2 h-5 w-5 group-hover:text-gray-dark"
                                            )}
                                            aria-hidden="true"
                                        />
                                    </button>
                                </a>
                            </Link>
                            <div className="hidden group-hover:block absolute z-10 -ml-4 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                        <Link href="/kompanije">
                                            <a>
                                                <div className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-light">
                                                    <OfficeBuildingIcon
                                                        className="flex-shrink-0 h-6 w-6 text-blue"
                                                        aria-hidden="true"
                                                    />
                                                    <div className="ml-4">
                                                        <span className="text-base font-medium">
                                                            Istraži kompanije
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </Link>

                                        <Link href="/ostavi-utisak">
                                            <a>
                                                <div className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-light">
                                                    <PencilAltIcon
                                                        className="flex-shrink-0 h-6 w-6 text-blue"
                                                        aria-hidden="true"
                                                    />
                                                    <div className="ml-4">
                                                        <span className="text-base font-medium">
                                                            Ostavi iskustvo o
                                                            radu
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </Link>

                                        <Link href="/ostavi-intervju">
                                            <a>
                                                <div className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-light">
                                                    <PencilAltIcon
                                                        className="flex-shrink-0 h-6 w-6 text-blue"
                                                        aria-hidden="true"
                                                    />
                                                    <div className="ml-4">
                                                        <span className="text-base font-medium">
                                                            Ostavi iskustvo sa
                                                            intervjua
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </Link>

                                        <Link href="/recenzije">
                                            <a>
                                                <div className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-light">
                                                    <PencilAltIcon
                                                        className="flex-shrink-0 h-6 w-6 text-blue"
                                                        aria-hidden="true"
                                                    />
                                                    <div className="ml-4">
                                                        <span className="text-base font-medium">
                                                            Pogledaj recenzije
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Link href="/kontakt">
                            <a className="text-base font-medium hover:text-blue py-1">
                                Kontakt
                            </a>
                        </Link>
                        <Link href="/blog">
                            <a className="text-base font-medium hover:text-blue py-1">
                                Blog
                            </a>
                        </Link>
                        <Link href="/meetups">
                            <a className="text-base font-medium hover:text-blue py-1">
                                Meetups
                            </a>
                        </Link>
                        {status === "authenticated" &&
                            (isAdmin || isCompanyPremium) && (
                                <a
                                    target="_blank"
                                    href={`${window.location.origin}/kreiraj/posao`}
                                    className="text-base font-medium hover:text-blue py-1"
                                >
                                    Dodaj posao
                                </a>
                            )}
                        {status === "authenticated" && isAdmin && (
                            <Link href="/kreiraj/kompaniju">
                                <a className="text-base font-medium hover:text-blue py-1">
                                    Dodaj kompaniju
                                </a>
                            </Link>
                        )}
                    </div>

                    <div className="hidden md:flex">
                        <p>
                            <Link href="/objavi-oglas">
                                <button className="btn-primary self-center mr-4">
                                    Objavi oglas
                                </button>
                            </Link>
                        </p>
                        {status === "unauthenticated" && (
                            <Link
                                href={`/auth/signin?redirect=${window.location.href}`}
                            >
                                <button className="btn-accent self-center">
                                    Prijava
                                </button>
                            </Link>
                        )}
                        {status === "authenticated" && (
                            <button
                                className="btn-accent self-center"
                                onClick={() => signOut()}
                            >
                                Odjavi se
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {open && (
                <div className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div className="rounded-lg shadow-lg bg-white">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <Link href="/">
                                        <img
                                            className="h-8 w-auto"
                                            src="/logo.svg"
                                            alt="Workflow"
                                        />
                                    </Link>
                                </div>
                                <div className="-mr-2">
                                    <button
                                        onClick={() => setOpen(false)}
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center focus:outline-none"
                                    >
                                        <span className="sr-only">
                                            Close menu
                                        </span>
                                        <XIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <div className="flex flex-col">
                                    <Link href="/">
                                        <a className="inline-block mb-4 text-base font-medium hover:text-blue">
                                            Poslovi
                                        </a>
                                    </Link>
                                </div>
                                <nav className="grid gap-y-8 grid-cols-2">
                                    {solutions.map((item) => (
                                        <div
                                            key={item.name}
                                            className="-m-3 p-3 flex flex-col rounded-md"
                                        >
                                            <div className="flex">
                                                <item.icon
                                                    className="flex-shrink-0 h-6 w-6 mb-2 text-blue"
                                                    aria-hidden="true"
                                                />
                                                <span className="ml-3 text-base font-medium">
                                                    {item.name}
                                                </span>
                                            </div>
                                            <ul className="mt-1 text-sm">
                                                {item.value.map((v) => (
                                                    <li
                                                        key={v.href}
                                                        className="text-base text-gray-dark hover:text-blue leading-8 ml-9"
                                                    >
                                                        <Link href={v.href}>
                                                            <a>{v.label}</a>
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </nav>

                                <div className="flex flex-col mt-6">
                                    <Link href="/kompanije">
                                        <a className="inline-block mb-4 text-base font-medium hover:text-blue">
                                            Kompanije
                                        </a>
                                    </Link>
                                </div>

                                <ul className="mt-1 text-sm">
                                    <li className="text-base text-gray-dark hover:text-blue leading-8">
                                        <Link href="/kompanije">
                                            <a>Istraži kompanije</a>
                                        </Link>
                                    </li>
                                    <li className="text-base text-gray-dark hover:text-blue leading-8">
                                        <Link href="/ostavi-utisak">
                                            <a>Ostavi iskustvo o radu</a>
                                        </Link>
                                    </li>
                                    <li className="text-base text-gray-dark hover:text-blue leading-8">
                                        <Link href="/ostavi-intervju">
                                            <a>Ostavi iskustvo sa intervjua</a>
                                        </Link>
                                    </li>
                                    <li className="text-base text-gray-dark hover:text-blue leading-8">
                                        <Link href="/recenzije">
                                            <a>Pogledaj recenzije</a>
                                        </Link>
                                    </li>
                                </ul>

                                <div className="flex flex-col mt-6">
                                    <Link href="/kontakt">
                                        <a className="inline-block mb-4 text-base font-medium hover:text-blue">
                                            Kontakt
                                        </a>
                                    </Link>
                                </div>
                                <div className="flex flex-col mt-6">
                                    <Link href="/blog">
                                        <a className="inline-block mb-4 text-base font-medium hover:text-blue">
                                            Blog
                                        </a>
                                    </Link>
                                </div>
                                <div className="flex flex-col mt-6">
                                    <Link href="/meetups">
                                        <a className="inline-block mb-4 text-base font-medium hover:text-blue">
                                            Meetups
                                        </a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 px-5 space-y-6 border-t border-gray">
                            <div>
                                <p className=" text-center text-base font-medium">
                                    {status === "unauthenticated" && (
                                        <Link
                                            href={`/auth/signin?redirect=${window.location.href}`}
                                        >
                                            <button className="btn-accent self-center">
                                                Prijava
                                            </button>
                                        </Link>
                                    )}
                                    {status === "authenticated" && (
                                        <Link href="/auth/signin">
                                            <button
                                                className="btn-accent self-center"
                                                onClick={() => signOut()}
                                            >
                                                Odjavi se
                                            </button>
                                        </Link>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
