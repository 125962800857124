import Link from "next/link";

export default function Copyright() {
    return (
        <div className="max-w-screen-xl mx-auto">
            {
                // @todo ovo sigurno moze bolje sa manje koda, al ovo bi najbrze
            }
            <div className="md:hidden flex flex-col items-baseline justify-between mt-8 space-y-4 md:flex-row lg:mt-10 pt-8 lg:pt-10 border-t border-gray">
                <img
                    className="block m-auto mb-8 h-10 md:inline md:mr-4"
                    src="/logo.svg"
                    alt="Dzobs logo"
                />
                <div className="text-center w-full lg:w-auto text-gray-dark">
                    <Link href="/team">
                        <a className="mr-2 hover:text-blue">Tim</a>
                    </Link>
                    |
                    <Link href="/uslovi">
                        <a className="mx-2 hover:text-blue">Uslovi</a>
                    </Link>
                    |
                    <Link href="/privatnost">
                        <a className="mx-2 hover:text-blue">Privatnost</a>
                    </Link>
                    |
                    <Link href="/faq">
                        <a className="mx-2 hover:text-blue">FAQ</a>
                    </Link>
                </div>
                <div className="text-center w-full lg:w-auto">
                    <span className="text-gray-dark text-sm">
                        &copy;2022 dzobs.com All rights reserved.
                    </span>
                </div>
            </div>
            <div className="hidden md:flex flex-col items-baseline justify-between mt-8 space-y-4 md:flex-row lg:mt-10 pt-8 lg:pt-10 border-t border-gray">
                <div className="text-center w-full lg:w-auto">
                    <img
                        className="h-8 inline mr-4"
                        src="/logo.svg"
                        alt="Dzobs logo"
                    />
                    <span className="text-gray-dark">
                        &copy;2022 dzobs.com All rights reserved.
                    </span>
                </div>
                <div className="text-center w-full lg:w-auto text-gray-dark">
                    <Link href="/team">
                        <a className="mr-2 hover:text-blue">Tim</a>
                    </Link>
                    |
                    <Link href="/uslovi">
                        <a className="mx-2 hover:text-blue">Uslovi</a>
                    </Link>
                    |
                    <Link href="/privatnost">
                        <a className="mx-2 hover:text-blue">Privatnost</a>
                    </Link>
                    |
                    <Link href="/faq">
                        <a className="mx-2 hover:text-blue">FAQ</a>
                    </Link>
                </div>
            </div>
        </div>
    );
}
